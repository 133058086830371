@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
	html {
		font-family: "Montserrat", sans-serif;
	}

	h2 {
		font-size: 32px;
		line-height: 40px;
	}
}

@font-face {
	font-family: "icomoon";
	src: local("icomoon"), url("./assets/fonts/icomoon.eot?fmnzmg");
	src: local("icomoon"), url("./assets/fonts/icomoon.eot?fmnzmg#iefix") format("embedded-opentype"),
		url("./assets/fonts/icomoon.ttf?fmnzmg") format("truetype"),
		url("./assets/fonts/icomoon.woff?fmnzmg") format("woff"),
		url("./assets/fonts/icomoon.svg?fmnzmg#icomoon") format("svg");
	font-weight: normal;
	font-style: normal;
	font-display: block;
}

@layer components {
	body {
		background-color: #faf2f1;
	}

	.list-comment li {
		display: flex;
		margin-bottom: 30px;
		position: relative;
	}

	.list-comment li.mb-0 {
		margin-bottom: 0 !important;
	}

	.list-comment li.replies::before {
		content: "";
		width: 0px;
		position: absolute;
		left: 23px;
		height: calc(100% - 40px);
		bottom: -20px;
		border: 1px solid #e7e7e7;
	}
	.list-comment li:last-child::before {
		display: none;
	}

	/* .list-comment li.replies:last-child::before {
		border: 1px dashed #911818;
	} */

	/* .list-comment li:first-child::before,
	.list-comment.reply li:last-child::before {
		display: none;
	} */

	.list-comment.reply li:first-child::before {
		display: block;
	}

	.like label::before {
		content: "\e93e";
		font-family: "icomoon";
		font-size: 14px;
		color: rgb(172, 172, 172);
	}

	.like input:checked + label::before {
		content: "\e93f";
		color: rgb(183, 2, 41);
	}

	.upload-file label:hover img {
		filter: invert(8%) sepia(85%) saturate(4552%) hue-rotate(349deg) brightness(71%) contrast(85%);
	}

	.skin-type input:checked + label {
		border-color: #ba697a;
		background: #faf2f1;
	}

	.skin-type input:checked + label span {
		color: #ba697a;
	}

	.skin-type-menu {
		position: absolute;
		inset: auto auto 0px 0px;
		margin: 0px;
		transform: translate3d(376px, 42.4px, 0px);
	}

	.menu:hover::before,
	.menu-active::before {
		content: "\e934";
		color: #fff;
		font-family: "icomoon";
		position: absolute;
		left: 0;
	}

	.breadcrumd li::after {
		content: "\e90b";
		font-family: "icomoon";
		padding-left: 16px;
		color: #b70229;
	}

	.breadcrumd li:last-child::after {
		display: none;
	}

	.profile {
		background: #fff;
	}

	.profile::after {
		content: "\e915";
		font-family: "icomoon";
		color: #b70229;
	}

	.profile-shadow {
		box-shadow: 0px 4px 8px rgba(255, 170, 188, 0.16);
	}

	.initial {
		width: 48px;
		height: 48px;
		border-radius: 50%;
		line-height: 48px;
		text-align: center;
		font-weight: 700;
		font-size: 24px;
		color: #990222;
		background: rgba(183, 2, 41, 0.2);
	}

	.for-status::after {
		content: "\e915";
		font-family: "icomoon";
		font-size: 16px;
		height: 28px;
	}

	.static-option {
		visibility: hidden;
		opacity: 0;
	}

	.for-status:hover ~ .static-option,
	.for-status:focus ~ .static-option {
		visibility: visible;
		opacity: 1;
	}

	.link-action li::after {
		content: "|";
		color: #e7e7e7;
		padding-left: 8px;
	}

	.link-action li:last-child::after {
		display: none;
	}

	.popup-item label::before {
		width: 10px;
		height: 10px;
		border-radius: 50%;
		content: "";
		position: absolute;
		right: 19px;
		background-color: #fff;
	}

	.popup-item label::after {
		border: 1px solid #acacac;
		width: 16px;
		height: 16px;
		border-radius: 50%;
		content: "";
		background-color: #fff;
	}

	.popup-item input:checked + label::before {
		background-color: #b70229;
	}

	.popup-item input:checked + label::after {
		border: 1px solid #b70229;
	}

	.no-ba::before,
	.no-ba::after {
		display: none;
	}

	.test-item label::after {
		width: 10px;
		height: 10px;
		border-radius: 50%;
		content: "";
		position: absolute;
		left: 3px;
		background-color: #fff;
	}

	.test-item label::before {
		border: 1px solid #acacac;
		min-width: 16px;
		min-height: 16px;
		border-radius: 50%;
		content: "";
		background-color: #fff;
	}

	.test-item input:checked + label::after {
		background-color: #b70229;
	}

	.test-item input:checked + label::before {
		border: 1px solid #b70229;
	}

	.scroll-menu::-webkit-scrollbar {
		width: 3px;
		border-radius: 3px;
	}

	.toggle {
		cursor: pointer;
		display: inline-block;
	}

	.toggle-switch {
		display: inline-block;
		background: #fff;
		border: 2px solid #5a5a5a;
		border-radius: 16px;
		width: 58px;
		height: 32px;
		position: relative;
		vertical-align: middle;
		transition: all 0.25s;
	}
	.toggle-switch::before {
		content: "";
	}
	.toggle-switch::before {
		display: block;
		background: #5a5a5a;
		border-radius: 50%;
		width: 24px;
		height: 24px;
		position: absolute;
		top: 2px;
		left: 2px;
		transition: left 0.25s;
	}
	.toggle-checkbox:checked + .toggle-switch {
		background: #eaf4e7;
		border: 2px solid #52933f;
	}
	.toggle-checkbox:checked + .toggle-switch::before {
		background: #52933f;
		left: 28px;
	}

	.toggle-checkbox {
		position: absolute;
		visibility: hidden;
		display: none;
	}

	.toggle-switch::after {
		content: "Inactive";
		position: absolute;
		width: 80px;
		left: -80px;
		top: 5px;
		font-weight: 600;
		font-size: 16px;
		line-height: 20px;
		color: #5a5a5a;
	}

	.toggle-checkbox:checked + .toggle-switch::after {
		content: "Active";
		top: 5px;
		color: #52933f;
	}

	.allow .toggle-switch::after {
		content: "Not Allow";
		width: 100px;
		left: -100px;
	}

	.allow .toggle-checkbox:checked + .toggle-switch::after {
		content: "Allow";
	}

	.enable .toggle-switch::after {
		content: "Disable";
		width: 100px;
		left: -100px;
	}

	.enable .toggle-checkbox:checked + .toggle-switch::after {
		content: "Enable";
	}

	.scroll {
		overflow-y: auto;
		overflow-x: hidden;
	}

	.scroll::-webkit-scrollbar {
		width: 3px;
		border-radius: 3px;
		margin-left: 5px;
	}

	/* Track */

	.scroll::-webkit-scrollbar-track {
		background: transparent;
	}

	/* Handle */

	.scroll::-webkit-scrollbar-thumb {
		background: #d3d3d3;
	}

	/* Track */
	.scroll-menu::-webkit-scrollbar-track {
		background: transparent;
	}

	/* Handle */
	.scroll-menu::-webkit-scrollbar-thumb {
		background: #f3f3f3;
	}

	.content-scroll {
		height: 45vh;
		overflow-y: auto;
		overflow-x: hidden;
	}

	.content-scroll::-webkit-scrollbar {
		width: 3px;
		border-radius: 3px;
	}

	/* Track */
	.content-scroll::-webkit-scrollbar-track {
		background: transparent;
	}

	/* Handle */
	.content-scroll::-webkit-scrollbar-thumb {
		background: #f3f3f3;
	}

	/* Side Menu Page setting */
	.side-item:hover::before,
	.side-active::before {
		content: "";
		background-image: url("./assets/images/hover.png");
		width: 8px;
		height: 40px;
		position: absolute;
		left: 0;
		top: 50%;
		transform: translateY(-50%);
	}

	[class^="icon-"],
	[class*=" icon-"] {
		/* use !important to prevent issues with browser extensions that change fonts */
		font-family: "icomoon" !important;
		speak: never;
		font-style: normal;
		font-weight: normal;
		font-variant: normal;
		text-transform: none;
		line-height: 1;

		/* Better Font Rendering =========== */
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
	}

	.icon-ico-activity::before {
		content: "\e900";
	}

	.icon-ico-activity-menu::before {
		content: "\e901";
	}

	.icon-ico-archive::before {
		content: "\e902";
	}

	.icon-ico-arrow-down::before {
		content: "\e903";
	}

	.icon-ico-arrow-up::before {
		content: "\e904";
	}

	.icon-ico-bullets::before {
		content: "\e905";
	}

	.icon-ico-campaign::before {
		content: "\e906";
	}

	.icon-ico-challange::before {
		content: "\e907";
	}

	.icon-ico-check::before {
		content: "\e908";
	}

	.icon-ico-chev-down::before {
		content: "\e909";
	}

	.icon-ico-chev-left::before {
		content: "\e90a";
	}

	.icon-ico-chev-right::before {
		content: "\e90b";
	}

	.icon-ico-chev-up::before {
		content: "\e90c";
	}

	.icon-ico-circle-check::before {
		content: "\e90d";
	}

	.icon-ico-circle-plus::before {
		content: "\e90e";
	}

	.icon-ico-close::before {
		content: "\e90f";
	}

	.icon-ico-comment::before {
		content: "\e910";
	}

	.icon-ico-course::before {
		content: "\e911";
	}

	.icon-ico-delete::before {
		content: "\e912";
	}

	.icon-ico-diamond::before {
		content: "\e913";
	}

	.icon-ico-discussion::before {
		content: "\e914";
	}

	.icon-ico-down::before {
		content: "\e915";
	}

	.icon-ico-edit::before {
		content: "\e916";
	}

	.icon-ico-events::before {
		content: "\e917";
	}

	.icon-ico-exam::before {
		content: "\e918";
	}

	.icon-ico-export::before {
		content: "\e919";
	}

	.icon-ico-eye::before {
		content: "\e91a";
	}

	.icon-ico-eye-close::before {
		content: "\e91b";
	}

	.icon-ico-facebook::before {
		content: "\e91c";
	}

	.icon-ico-file::before {
		content: "\e91d";
	}

	.icon-ico-filter::before {
		content: "\e91e";
	}

	.icon-ico-flag::before {
		content: "\e91f";
	}

	.icon-ico-homepage::before {
		content: "\e920";
	}

	.icon-ico-img::before {
		content: "\e921";
	}

	.icon-ico-instagram::before {
		content: "\e922";
	}

	.icon-ico-level::before {
		content: "\e923";
	}

	.icon-ico-link::before {
		content: "\e924";
	}

	.icon-ico-linkedin::before {
		content: "\e925";
	}

	.icon-ico-meeting::before {
		content: "\e926";
	}

	.icon-ico-page::before {
		content: "\e927";
	}

	.icon-ico-pencil-edit::before {
		content: "\e928";
	}

	.icon-ico-plane::before {
		content: "\e929";
	}

	.icon-ico-plus::before {
		content: "\e92a";
	}

	.icon-ico-reply::before {
		content: "\e92b";
	}

	.icon-ico-review::before {
		content: "\e92c";
	}

	.icon-ico-save::before {
		content: "\e92d";
	}

	.icon-ico-search::before {
		content: "\e92e";
	}

	.icon-ico-setting::before {
		content: "\e92f";
	}

	.icon-ico-snapchat::before {
		content: "\e930";
	}

	.icon-ico-sort::before {
		content: "\e931";
	}

	.icon-ico-tiktok::before {
		content: "\e932";
	}

	.icon-ico-time::before {
		content: "\e933";
	}

	.icon-ico-triangle::before {
		content: "\e934";
	}

	.icon-ico-twitter::before {
		content: "\e935";
	}

	.icon-ico-up::before {
		content: "\e936";
	}

	.icon-ico-upload::before {
		content: "\e937";
	}

	.icon-ico-user::before {
		content: "\e938";
	}

	.icon-ico-user-register::before {
		content: "\e939";
	}

	.icon-ico-whatsapp::before {
		content: "\e93a";
	}

	.icon-ico-youtube::before {
		content: "\e93b";
	}
}
