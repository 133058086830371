/*======================================
  Selectric
======================================*/
.selectric-wrapper {
  position: relative;
  cursor: pointer;
}

.selectric-responsive {
  width: 100%;
}

.selectric {
  background: #FFFFFF;
  border: 1px solid #E7E7E7;
  position: relative;
  box-shadow: none;
  border-radius: 8px;
}

.selectric-is-invalid .selectric {
  border: 1px solid #DB163C;
  border-bottom-width: 1px;
}

.selectric .label {
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin: 0 30px 0 0;
  font-size: 14px;
  line-height: 20px;
  color: rgb(var(--color-black));
  min-height: 18px;
  font-weight: 600;
  padding: 11px 15px;
}


.selectric .button {
  display: block;
  position: absolute;
  right: 5px;
  top: 7px;
  width: 30px;
  height: 30px;
  background: none;
  font-size: 14px;
  text-align: center;
  line-height: 30px;
  color: rgb(var(--color-red));
}

@media \0screen\,screen\9 {
  .selectric .button {
    color: rgb(var(--color-red));
    text-align: center;
    font: 20px/30px Lucida Sans Unicode, Arial Unicode MS, Arial;
  }
}

.selectric-focus .selectric {
  border-color: rgb(var(--color-red));
}

.selectric-hover .selectric {
  border-color: rgb(var(--color-red));
}

.selectric-hover .selectric .button {
  color: rgb(var(--color-red));
}

.selectric-hover .selectric .button:after {
  border-top-color: rgb(var(--color-red));
}

.selectric-open {
  z-index: 9999;
}

.selectric-open .selectric {
  color: rgb(var(--color-grey-900));
  background-color: #fff;
  border-color: rgb(var(--color-red-10));
  outline: 0;
  border-radius: 8px;
  box-shadow: none;
}

.selectric-open .selectric .label {
  color: rgb(var(--color-black));
  border-radius: 8px;
}

.selectric-open .selectric-items {
  visibility: visible;
  opacity: 1;
  -webkit-transform: translateY(0);
  -ms-transform: translateY(0);
  -o-transform: translateY(0);
  transform: translateY(0);
  -webkit-transition: .3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  transition: .3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
}

.selectric-disabled {
  filter: alpha(opacity=50);
  opacity: 0.5;
  cursor: default;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.selectric-hide-select {
  position: relative;
  overflow: hidden;
  width: 0;
  height: 0;
}

.selectric-hide-select select {
  position: absolute;
  left: -100%;
}

.selectric-hide-select.selectric-is-native {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 10;
}

.selectric-hide-select.selectric-is-native select {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  border: none;
  z-index: 1;
  box-sizing: border-box;
  opacity: 0;
}

.selectric-input {
  position: absolute !important;
  top: 0 !important;
  left: 0 !important;
  overflow: hidden !important;
  clip: rect(0, 0, 0, 0) !important;
  margin: 0 !important;
  padding: 0 !important;
  width: 1px !important;
  height: 1px !important;
  outline: none !important;
  border: none !important;
  *font: 0/0 a !important;
  background: none !important;
}

.selectric-temp-show {
  position: absolute !important;
  visibility: hidden !important;
  display: block !important;
}

/* Items box */
.selectric-items {
  visibility: hidden;
  opacity: 0;
  -webkit-transform: translateY(-20px);
  -ms-transform: translateY(-20px);
  -o-transform: translateY(-20px);
  transform: translateY(-20px);
  position: absolute;
  top: 100%;
  left: 0;
  background: #fefefe;
  border: 1px solid transparent;
  z-index: -1;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.16);
  border-radius: 16px;
  margin: 8px 0;
  padding: 15px;
}



.selectric-items:before,
.selectric-items:after {
  display: none;
  content: "";
  position: absolute;
  top: -9px;
  left: 12px;
  width: 0;
  height: 0;
  border-bottom: 6px solid #BBB;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  margin-top: 3px;
}

.selectric-items:after {
  margin-top: 4px;
  border-bottom-color: #FFF;
}

.selectric-above .selectric-items:before,
.selectric-above .selectric-items:after {
  top: 100%;
  border-bottom-width: 0;
  border-top: 6px solid #BBB;
  margin-top: 0;
}

.selectric-above .selectric-items:after {
  border-top-color: #FFF;
  margin-top: -1px;
}

.selectric-above .selectric-items {
  top: auto;
  bottom: 100%;
}

.selectric-items ul,
.selectric-items li {
  list-style: none;
  padding: 0;
  margin: 0;
  font-size: 14px;
  line-height: 150%;
}

.selectric-items li {
  display: block;
  padding: 6px 30px 6px 10px;
  color: rgb(var(--color-black));
  cursor: pointer;
  position: relative;
  overflow: hidden;
  margin-bottom: 5px;
}


.selectric-form-location .selectric-items li:first-child {
  display: none;
}

.selectric-items li:first-child.selected.highlighted {
  background: #FFF7F8;
  color: rgb(var(--color-grey-900));
  border-radius: 0;
}

.selectric-items li.selected {
  background: #FFF7F8;
  color: rgb(var(--color-grey-900));
}

.selectric-items li.highlighted {
  background: #FFF7F8;
  color: rgb(var(--color-grey-900));
}

.selectric-items li:after {
  position: absolute;
  z-index: 3;
  top: 5px;
  right: -10px;
  width: 30px;
  height: 100%;
  opacity: 0;
  -webkit-transition: .2s;
  transition: .2s;
}

.selectric-items li.selected:after {
  opacity: 1;
  right: 0;
}

.selectric-items li {
  background: #FFFFFF;
  border: 1px solid #E7E7E7;
  border-radius: 8px;
  padding: 10px;
  text-align: center;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
}

.selectric-items li:hover {
  background: #FFF7F8;
  color: rgb(var(--color-grey-900));
}

.selectric-items .disabled {
  filter: alpha(opacity=50);
  opacity: 0.5;
  cursor: default !important;
  background: none !important;
  color: #666 !important;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.selectric-items .selectric-group .selectric-group-label {
  font-weight: bold;
  padding-left: 10px;
  cursor: default;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background: none;
  color: #444;
}

.selectric-items .selectric-group.disabled li {
  filter: alpha(opacity=100);
  opacity: 1;
}

.selectric-items .selectric-group li {
  padding-left: 25px;
}

.selectric-scroll {
  height: auto;
  overflow-y: auto;
  overflow-x: hidden;
}

.selectric-scroll::-webkit-scrollbar {
  width: 5px;
  border-radius: 10px;
  visibility: hidden;
}

.selectric-scroll::-webkit-scrollbar-track {
  background: transparent;
}

.selectric-scroll::-webkit-scrollbar-thumb {
  background: #DFDFDF;
  border-radius: 10px;
}