@font-face {
	font-family: "icomoon";
	src: url("../fonts/icomoon.eot?ofonn9");
	src: url("../fonts/icomoon.eot?ofonn9#iefix") format("embedded-opentype"),
		url("../fonts/icomoon.ttf?ofonn9") format("truetype"), url("../fonts/icomoon.woff?ofonn9") format("woff"),
		url("../fonts/icomoon.svg?ofonn9#icomoon") format("svg");
	font-weight: normal;
	font-style: normal;
	font-display: block;
}

[class^="icon-"],
[class*=" icon-"] {
	/* use !important to prevent issues with browser extensions that change fonts */
	font-family: "icomoon" !important;
	speak: never;
	font-style: normal;
	font-weight: normal;
	font-variant: normal;
	text-transform: none;
	line-height: 1;

	/* Better Font Rendering =========== */
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.icon-ico-activity:before {
	content: "\e900";
}
.icon-ico-activity-menu:before {
	content: "\e901";
}
.icon-ico-archive:before {
	content: "\e902";
}
.icon-ico-arrow-down:before {
	content: "\e903";
}
.icon-ico-arrow-up:before {
	content: "\e904";
}
.icon-ico-bullets:before {
	content: "\e905";
}
.icon-ico-campaign:before {
	content: "\e906";
}
.icon-ico-challange:before {
	content: "\e907";
}
.icon-ico-check:before {
	content: "\e908";
}
.icon-ico-chev-down:before {
	content: "\e909";
}
.icon-ico-chev-left:before {
	content: "\e90a";
}
.icon-ico-chev-right:before {
	content: "\e90b";
}
.icon-ico-chev-up:before {
	content: "\e90c";
}
.icon-ico-circle-check:before {
	content: "\e90d";
}
.icon-ico-circle-plus:before {
	content: "\e90e";
}
.icon-ico-close:before {
	content: "\e90f";
}
.icon-ico-comment:before {
	content: "\e910";
}
.icon-ico-course:before {
	content: "\e911";
}
.icon-ico-delete:before {
	content: "\e912";
}
.icon-ico-diamond:before {
	content: "\e913";
}
.icon-ico-discussion:before {
	content: "\e914";
}
.icon-ico-down:before {
	content: "\e915";
}
.icon-ico-edit:before {
	content: "\e916";
}
.icon-ico-events:before {
	content: "\e917";
}
.icon-ico-exam:before {
	content: "\e918";
}
.icon-ico-export:before {
	content: "\e919";
}
.icon-ico-eye:before {
	content: "\e91a";
}
.icon-ico-eye-close:before {
	content: "\e91b";
}
.icon-ico-facebook:before {
	content: "\e91c";
}
.icon-ico-file:before {
	content: "\e91d";
}
.icon-ico-filter:before {
	content: "\e91e";
}
.icon-ico--flg:before {
	content: "\e91f";
}
.icon-ico-homepage:before {
	content: "\e920";
}
.icon-ico-img:before {
	content: "\e921";
}
.icon-ico-instagram:before {
	content: "\e922";
}
.icon-ico-level:before {
	content: "\e923";
}
.icon-ico-link:before {
	content: "\e924";
}
.icon-ico-linkedin:before {
	content: "\e925";
}
.icon-ico-meeting:before {
	content: "\e926";
}
.icon-ico-page:before {
	content: "\e927";
}
.icon-ico-pencil-edit:before {
	content: "\e928";
}
.icon-ico-plane:before {
	content: "\e929";
}
.icon-ico-plus:before {
	content: "\e92a";
}
.icon-ico-reply:before {
	content: "\e92b";
}
.icon-ico-review:before {
	content: "\e92c";
}
.icon-ico-review-diamod:before {
	content: "\e92d";
}
.icon-ico-save:before {
	content: "\e92e";
}
.icon-ico-search:before {
	content: "\e92f";
}
.icon-ico-setting:before {
	content: "\e930";
}
.icon-ico-snapchat:before {
	content: "\e931";
}
.icon-ico-sort:before {
	content: "\e932";
}
.icon-ico-tiktok:before {
	content: "\e933";
}
.icon-ico-time:before {
	content: "\e934";
}
.icon-ico-triangle:before {
	content: "\e935";
}
.icon-ico-twitter:before {
	content: "\e936";
}
.icon-ico-up:before {
	content: "\e937";
}
.icon-ico-upload:before {
	content: "\e938";
}
.icon-ico-user:before {
	content: "\e939";
}
.icon-ico-user-register:before {
	content: "\e93a";
}
.icon-ico-whatsapp:before {
	content: "\e93b";
}
.icon-ico-youtube:before {
	content: "\e93c";
}

.icon-ico-bubble:before {
	content: "\e93c";
}

.icon-ico-flag:before {
	content: "\e93d";
}

.icon-ico-heart:before {
	content: "\e93e";
}

.icon-ico-reward:before {
	content: "\e940";
}

.icon-ico-topic:before {
	content: "\e941";
}

.icon-ico-x-circle:before {
	content: "\e942";
}

.icon-ico-activity-menu:before {
	content: "\e901";
}

.icon-ico-archive:before {
	content: "\e902";
}

.icon-ico-arrow-down:before {
	content: "\e903";
}

.icon-ico-arrow-up:before {
	content: "\e904";
}

.icon-ico-bullets:before {
	content: "\e905";
}

.icon-ico-challange:before {
	content: "\e907";
}

.icon-ico-check:before {
	content: "\e908";
}

.icon-ico-chev-down:before {
	content: "\e909";
}

.icon-ico-chev-right:before {
	content: "\e90b";
}

.icon-ico-circle-check:before {
	content: "\e90d";
}

.icon-ico-circle-plus:before {
	content: "\e90e";
}

.icon-ico-close:before {
	content: "\e90f";
}

.icon-ico-comment:before {
	content: "\e910";
}

.icon-ico-course:before {
	content: "\e911";
}

.icon-ico-delete:before {
	content: "\e912";
}

.icon-ico-down:before {
	content: "\e915";
}

.icon-ico-edit:before {
	content: "\e916";
}

.icon-ico-events:before {
	content: "\e917";
}

.icon-ico-exam:before {
	content: "\e918";
}

.icon-ico-export:before {
	content: "\e919";
}

.icon-ico-eye:before {
	content: "\e91a";
}

.icon-ico-facebook:before {
	content: "\e91c";
}

.icon-ico-file:before {
	content: "\e91d";
}

.icon-ico-filter:before {
	content: "\e91e";
}

.icon-ico-img:before {
	content: "\e921";
}

.icon-ico-instagram:before {
	content: "\e922";
}

.icon-ico-link:before {
	content: "\e924";
}

.icon-ico-linkedin:before {
	content: "\e925";
}

.icon-ico-page:before {
	content: "\e927";
}

.icon-ico-pencil-edit:before {
	content: "\e928";
}

.icon-ico-plane:before {
	content: "\e929";
}

.icon-ico-plus:before {
	content: "\e92a";
}

.icon-ico-reply:before {
	content: "\e92b";
}

.icon-ico-review:before {
	content: "\e92c";
}

.icon-ico-save:before {
	content: "\e92d";
}

.icon-ico-search:before {
	content: "\e92e";
}

.icon-ico-setting:before {
	content: "\e92f";
}

.icon-ico-snapchat:before {
	content: "\e930";
}

.icon-ico-sort:before {
	content: "\e931";
}

.icon-ico-tiktok:before {
	content: "\e932";
}

.icon-ico-time:before {
	content: "\e933";
}

.icon-ico-twitter:before {
	content: "\e935";
}

.icon-ico-upload:before {
	content: "\e937";
}

.icon-ico-user:before {
	content: "\e938";
}

.icon-ico-user-register:before {
	content: "\e939";
}

.icon-ico-whatsapp:before {
	content: "\e93a";
}

.icon-ico-youtube:before {
	content: "\e93b";
}
