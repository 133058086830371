.main-bg {
	background-position: center;
	background-image: url("../../../public/img/main-bg.png");
}
.form-input-error {
	color: #b74949;
	font-size: 12px;
}
.form-control-error {
	border: 1px solid #b74949 !important;
}

.form-input-error.bg {
	background-color: rgba(241, 111, 109, 0.1);
	border: solid 1px #f16f6d;
	line-height: 1.4;
	padding: 15px;
}

.btn-submit[disabled] {
	cursor: not-allowed;
	background-color: #b97676 !important;
	border: 1px solid #b97676 !important;
}

.loader {
	position: relative;
}
.loader:before,
.loader:after {
	content: "";
	width: 38px;
	height: 38px;
	display: block;
	position: relative;
	margin: 10px auto;
	border-radius: 50%;
	background: #b70229;
	animation: left 1s infinite ease-in-out;
}
.loader:after {
	background: #e85353;
	animation: right 1s infinite ease-in-out;
}

@keyframes right {
	0%,
	100% {
		transform: translate(-15px);
	}
	50% {
		transform: translate(15px);
	}
}

@keyframes left {
	0%,
	100% {
		transform: translate(15px);
	}
	50% {
		transform: translate(-15px);
	}
}

/* Button loading animation spinner */

.spinner {
	margin: 0px auto 0;
	width: 70px;
	text-align: center;
}
.spinner-colorful > div {
	background: linear-gradient(-90deg, #073649 0%, #457489 100%) !important;
	background: -webkit-linear-gradient(-90deg, #073649 0%, #457489 100%) !important;
}
.spinner > div {
	width: 11px;
	height: 11px;
	background-color: #fff;
	margin-right: 5px;
	border-radius: 100%;
	display: inline-block;
	-webkit-animation: sk-bouncedelay 1.4s infinite ease-in-out both;
	animation: sk-bouncedelay 1.4s infinite ease-in-out both;
}

.spinner .bounce1 {
	-webkit-animation-delay: -0.32s;
	animation-delay: -0.32s;
}

.spinner .bounce2 {
	-webkit-animation-delay: -0.16s;
	animation-delay: -0.16s;
}

.custom-datepicker-modal-container {
	position: relative;
	display: block;
	width: 100%;
}

@-webkit-keyframes sk-bouncedelay {
	0%,
	80%,
	100% {
		-webkit-transform: scale(0);
	}
	40% {
		-webkit-transform: scale(1);
	}
}

@keyframes sk-bouncedelay {
	0%,
	80%,
	100% {
		-webkit-transform: scale(0);
		transform: scale(0);
	}
	40% {
		-webkit-transform: scale(1);
		transform: scale(1);
	}
}

/* Checkmark icon animation */
svg {
	width: 100px;
	display: block;
	margin: 40px auto 0;
}
.path {
	stroke-dasharray: 1000;
	stroke-dashoffset: 0;
}
.path.circle {
	-webkit-animation: dash 0.9s ease-in-out;
	animation: dash 0.9s ease-in-out;
}
.path.line {
	stroke-dashoffset: 1000;
	-webkit-animation: dash 0.9s 0.35s ease-in-out forwards;
	animation: dash 0.9s 0.35s ease-in-out forwards;
}
.path.check {
	stroke-dashoffset: -100;
	-webkit-animation: dash-check 0.9s 0.35s ease-in-out forwards;
	animation: dash-check 0.9s 0.35s ease-in-out forwards;
}

@-webkit-keyframes dash {
	0% {
		stroke-dashoffset: 1000;
	}
	100% {
		stroke-dashoffset: 0;
	}
}
@keyframes dash {
	0% {
		stroke-dashoffset: 1000;
	}
	100% {
		stroke-dashoffset: 0;
	}
}
@-webkit-keyframes dash-check {
	0% {
		stroke-dashoffset: -100;
	}
	100% {
		stroke-dashoffset: 900;
	}
}
@keyframes dash-check {
	0% {
		stroke-dashoffset: -100;
	}
	100% {
		stroke-dashoffset: 900;
	}
}

/* Remove am pm from input type time picker */
.without_ampm::-webkit-datetime-edit-ampm-field {
	display: none;
}
input[type="time"]::-webkit-clear-button {
	-webkit-appearance: none;
	-moz-appearance: none;
	-o-appearance: none;
	-ms-appearance: none;
	appearance: none;
	margin: -10px;
}

/* lds spinner */
.loader-ring {
	width: 30px;
	height: 30px;
	border: 3px solid #b70229;
	border-bottom-color: transparent;
	border-radius: 50%;
	display: inline-block;
	box-sizing: border-box;
	animation: rotation 1s linear infinite;
}

@keyframes rotation {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}
